<template>
  <v-app>
    <v-app-bar dark class="ma-0" app fixed light elevation="5">
      <v-container>
        <v-app-bar
          elevation="0"
          class="mx-auto"
          app
          color="dark"
          max-width="1000"
          dark
        >
          <v-app-bar-nav-icon
            v-if="isMobile"
            @click.stop="showbar = true"
          ></v-app-bar-nav-icon>
          <div class="d-flex align-center">
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="@/assets/Image0001.png"
              width="250"
            />
          </div>

          <v-btn
            class="textglow"
            v-if="!isMobile"
            to="/"
            v-ripple="false"
            width="80"
            height="65"
            tile
            icon
          >
            HOME
          </v-btn>
          <v-btn
            class="textglow"
            v-if="!isMobile"
            to="/about"
            v-ripple="false"
            width="80"
            height="65"
            tile
            icon
          >
            ABOUT
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="!isMobile"
            href="https://discord.gg/r8UFtCetsC"
            target="_blank"
            text
          >
            <span class="mr-2 textglow">Join discord</span>
            <v-icon>mdi-discord</v-icon>
          </v-btn>
          <v-btn
            v-if="!isMobile"
            href="https://store.escapefromminecraft.net"
            target="_blank"
            text
          >
            <span class="mr-2 textglow">Store</span>
            <v-icon>mdi-store</v-icon>
          </v-btn>
        </v-app-bar>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="showbar" fixed temporary>
      <v-list dense>
        <v-list-item-group active-class=" text--accent-4 textglow">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>HOME</v-list-item-title>
          </v-list-item>

          <v-list-item to="/about">
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-title>ABOUT</v-list-item-title>
          </v-list-item>

          <v-divider class="mt-2 mb-2"></v-divider>

          

          <v-list-item to="">
            <v-list-item-icon>
              <v-icon>mdi-store</v-icon>
            </v-list-item-icon>
            <v-list-item-title>STORE</v-list-item-title>
          </v-list-item>
          <v-list-item to="">
            <v-list-item-icon>
              <v-icon>mdi-discord</v-icon>
            </v-list-item-icon>
            <v-list-item-title>DISCORD</v-list-item-title>
          </v-list-item>

          
        </v-list-item-group>
      </v-list>
    
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    isMobile: false,
    showbar: false,
  }),

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 800;
      window.isMobile = window.innerWidth < 800;
      console.log(this.showbar);
    },
  },
};
</script>

<style>
.textglow {
  text-shadow: var(--v-primary-base) 0px 0px 8px;
}
</style>
