<template>
  <div>
    <v-container grid-list-xs>
      <v-img max-width="1000" class="mx-auto" src="@/assets/Image0001.png"></v-img>
    </v-container>


    <countdown-timer/>
    <PrePurs/>



    <v-card-actions class="mx-auto">
      <v-btn outlined x-large color="primary" class="mx-auto mt-5 textglow">SIGN UP FOR APLHA TEST</v-btn>
    </v-card-actions>
    <v-card max-width="400" class="mx-auto mt-7">
          <iframe src="https://discord.com/widget?id=929738125255802920&theme=dark" width="400" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
    </v-card>

  </div>
</template>

<script>
import CountdownTimer from '../components/CountdownTimer.vue'
import PrePurs from '../components/PrePurs'


  export default {
    name: 'Home',

    components: {
        CountdownTimer,
        PrePurs


    },
  }
</script>
<style scoped>

</style>
