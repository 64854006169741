<template>
  <div id="countdown">
    <v-card class="mx-auto transparent" min-height="500" elevation="0" max-width="1000">

			<v-card-title primary-title class="justify-center">
				<h2 class="display-2 white--text text-center textglow">RELEASE</h2>
			</v-card-title>
			<v-divider class="ma-5"></v-divider>
      <v-card-text>




        <v-row class="text-center">
          <v-col>
            <v-card outlined>
              <p class="display-2 mt-5 textglow">{{ days }}</p>
              <v-card-text class="primary--text"> DAYS </v-card-text>
            </v-card>
          </v-col>
          <v-col
            ><v-card outlined>
              <p class="display-2 mt-5 textglow">{{ hours }}</p>
              <v-card-text class="primary--text"> HOURS </v-card-text>
            </v-card></v-col
          >
          <v-col
            ><v-card outlined>
              <p class="display-2 mt-5 textglow">{{ minutes }}</p>
              <v-card-text class="primary--text"> MINUTES </v-card-text>
            </v-card></v-col
          >
          <v-col
            ><v-card outlined>
              <p class="display-2 mt-5 textglow">{{ seconds }}</p>
              <v-card-text class="primary--text"> SECONDS </v-card-text>
            </v-card></v-col
          >
        </v-row>
				<p class="text-center  mt-5">Subject to change</p>
      </v-card-text>
    </v-card>




  </div>
</template>

<script>
export default {
  name: "CountdownTimer",

  data() {
    return {
      date: new Date("Apr 30, 2022 18:00:00").getTime(),
      timetext: "",
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },

  beforeCreate() {
    var x = setInterval(() => {
      let now = new Date().getTime();
      let distance = this.date - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(x);
      }
    }, 1000);
  },
};
</script>

<style>

</style>