<template>
  <div>
    <v-card class="mx-auto transparent" elevation="0" max-width="1000">
      <v-card-title primary-title class="justify-center">
        <h2 class="display-2 white--text text-center textglow">PRE-PURCHASE</h2>
      </v-card-title>
      <v-divider class="ma-5"></v-divider>
      <v-card-text>
        <v-row class="text-center">
          <v-col>
            <v-card
              class="glowhovercard d-flex flex-column"
              outlined
              min-height="320"
              max-width="450"
            >
              <v-card-title class="textglow" primary-title>
                Premium
              </v-card-title>
              <v-card-subtitle class="text-left">$4.99</v-card-subtitle>
              <v-card-text class="primary--text text-left">
                <ul>
                  <li>Increased stash size by 1 single chest</li>
                  <li>Premium name prefix in chat</li>
                </ul>
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn
                  target="_blank"
                  href="https://store.escapefromminecraft.net/package/4893966"
                  width="100%"
                  outlined
                  class="glowhover"
                  color="primary"
                  >BUY</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              class="d-flex flex-column glowhovercard"
              min-height="320"
              outlined
              max-width="450"
            >
              <v-card-title class="textglow" primary-title>
                Premium +
              </v-card-title>
              <v-card-subtitle class="text-left">$9.99</v-card-subtitle>
              <v-card-text class="primary--text text-left">
                <ul>
                  <li>Increased stash size by 1 double chest</li>
                  <li>Premium + name prefix in chat</li>
                  <li>1.25x hideout building time</li>
                </ul>
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn
                  width="100%"
                  target="_blank"
                  href="https://store.escapefromminecraft.net/package/4895009"
                  outlined
                  class="glowhover"
                  color="primary"
                  >BUY</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              class="d-flex flex-column glowhovercard"
              min-height="320"
              outlined
              max-width="450"
            >
              <v-card-title class="textglow" primary-title>
                Exclusive
              </v-card-title>
              <v-card-subtitle class="text-left">$16.99</v-card-subtitle>
              <v-card-text class="primary--text text-left">
                <ul>
                  <li>
                    Increased stash size by 1 double chest + 1 single chest
                  </li>
                  <li>Exclusive name prefix in chat</li>
                  <li>Queue priority</li>
                  <li>2x hideout building time</li>
                </ul>
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn
                  href="https://store.escapefromminecraft.net/package/4895011"
                  target="_blank"
                  width="100%"
                  outlined
                  class="glowhover"
                  color="primary"
                  >BUY</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              class="d-flex flex-column glowhovercard"
              min-height="320"
              outlined
              max-width="450"
            >
              <v-card-title class="textglow" primary-title>
                Exclusive +
              </v-card-title>
              <v-card-subtitle class="text-left">$24.99</v-card-subtitle>
              <v-card-text class="primary--text text-left">
                <ul>
                  <li>
                    Increased stash size by 2 double chest + 1 single chest
                  </li>
                  <li>Exclusive + name prefix in chat</li>
                  <li>Queue priority</li>
                  <li>3x hideout building time</li>
                </ul>
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn
                  href="https://store.escapefromminecraft.net/package/4895013"
                  target="_blank"
                  width="100%"
                  outlined
                  class="glowhover"
                  color="primary"
                  >BUY</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "PrePurs",
};
</script>
<style scoped>
.glowhover {
  transition-property: all;
  transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}
.glowhover:hover {
  text-shadow: var(--v-primary-base) 0px 0px 8px;
}

.glowhovercard {
  transition-property: all;
  transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

.glowhovercard:hover {
  box-shadow: var(--v-primary-base) 0px 0px 10px;
}
</style>
